import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  PreloadAllModules,
  ExtraOptions,
} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/basic/basic.module').then((m) => m.BasicModule),
  },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./@theme/layouts/complete-layout/complete-layout.module').then((m) => m.CompleteLayoutModule),
  // },
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/blank/blank.module').then((m) => m.BlankModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy',
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
